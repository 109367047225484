import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "A Drilldown Piechart with React and D3",
  "description": "A few days ago Kiran B sent me a challenge via Twitter DM: How can I make a drilldown piechart?",
  "date": "2019-06-28T08:00:00.000Z",
  "published": "2019-06-28T08:00:00.000Z",
  "image": "./img/iuvfIs3.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A few days ago Kiran B sent me a challenge via Twitter DM: How can I make a drilldown piechart?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b9f8a1526ec759fab16e6405c1df658/62a6a/iuvfIs3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.09417040358744%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'293\\'%20viewBox=\\'0%200%20400%20293\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M177%2014c-2%200-4%203-3%205h6c0%202%204%201%205-1h1c0%202%202%203%202%201h1c0%202%202%201%203-1s1-2%201%200v3l1-3c1-2%201-2%202%200l1%202v-5c-2-1-6%200-6%201h-4l-1-1h-5c-2%202-2%202-2%200%200-1-1-2-2-1m-54%2046c0%204%202%206%205%203%201-2%202-1%203%200h6c1%202%203%200%203-4%200-2%200-3-1-2l-1%203c0%201%200%202-1%201l-2-2c0-3-3-4-5-2l-1%201-3-2c-3%200-3%200-3%204M59%2073c-10%2010-7%2027%204%2034l4%203%2010%202c6-2%2011-4%2011-6-1%200%200-2%202-3%205-5%208-14%204-14l-3%203c-3%205-10%208-16%208-14-2-20-18-11-29%204-4-1-2-5%202m12-2c-12%206-8%2025%206%2025%2013%200%2018-18%206-24-4-2-7-2-12-1M14%2098c-5%202-7%206-8%2011%200%204%200%205%204%209s4%204%208%204c11%200%2016-10%2012-19-3-5-11-7-16-5m25%2020a515%20515%200%200075%201%20730%20730%200%2000-75-1m8%2033c0%201%202%204%204%204v1h-4c1%203%205%202%206%200l1-2v2c1%202%201%202%202%201h2c1%201%201%201%202-1s1-2%201%200c0%201%201%202%204%202s3%200%202-1v-3c1-1%201-1%201%201s2%204%204%202h5v-1l-2-1c0-1%201-2%202-1l1-1-2-1-2%201h-1l-6-1-5-1v-1s-3%202-3%204h-1l-1-2-1%202h-1c-1-3-6-5-8-3m-32%205c-7%202-12%2011-8%2018%202%203%202%202%205-3%202-5%202-5%204-3h2l1-3c-1-1%203-2%206-1%204%202%205%203%204%204h-1c-1-2-6-2-8%200v5l-2-1-1-1h-3l-2%203c-2%204-1%206%204%207%204%200%204%200%203-2-1-3-1-4%203-4h2l-2%202c-4%204%200%205%205%201%2010-9%202-25-12-22m-3%2046c-6%203-8%2012-5%2017h3c2-3%203-2%201%201-1%202%202%205%206%205%203%200%203%200%202-2v-3h5l-1%201-2%202c0%202%200%202%204%200%208-4%208-16%201-21-4-2-10-3-14%200m2%2053c-7%203-10%2013-6%2018l2%201%201-2v2c0%203%200%203%203%204%206%203%2013%200%2016-6%205-11-6-22-16-17\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6b9f8a1526ec759fab16e6405c1df658/ca0a1/iuvfIs3.webp 223w", "/static/6b9f8a1526ec759fab16e6405c1df658/75680/iuvfIs3.webp 445w", "/static/6b9f8a1526ec759fab16e6405c1df658/8d1ba/iuvfIs3.webp 890w", "/static/6b9f8a1526ec759fab16e6405c1df658/e6b69/iuvfIs3.webp 1122w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6b9f8a1526ec759fab16e6405c1df658/e92b6/iuvfIs3.png 223w", "/static/6b9f8a1526ec759fab16e6405c1df658/e66bf/iuvfIs3.png 445w", "/static/6b9f8a1526ec759fab16e6405c1df658/4ef49/iuvfIs3.png 890w", "/static/6b9f8a1526ec759fab16e6405c1df658/62a6a/iuvfIs3.png 1122w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6b9f8a1526ec759fab16e6405c1df658/4ef49/iuvfIs3.png",
              "alt": "iuvfIs3",
              "title": "iuvfIs3",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The annoyed emoji is a bit much but I`}{`'`}{`ll allow it. The challenge was fun to solve. Got it roughly working in a 45min stream 👇`}</p>
    <lite-youtube {...{
      "videoid": "adZ61pB7iFI",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`You can also `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/drilldown-piechart-in-react-and-d3-d62y5"
      }}>{`try it out via CodeSandbox`}</a>{`. I`}{`'`}{`m surprised how nice default D3 category colors work with a black border. It`}{`'`}{`s gorgeous 😍`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6988ed2d8e5288c18ed315385e50f45e/587b0/NU4bfJZ.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "84.30493273542601%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'337\\'%20viewBox=\\'0%200%20400%20337\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M190%2067c-47%206-87%2046-93%2093l-1%206-5-1-10-1h-5v5a130%20130%200%2000107%20134h5l1-10%201-9%2016-1c88%200%20138-98%2088-170-23-33-64-51-104-46m9%202h4v45a884%20884%200%20001%200V68h-8l3%201m-18%201l-8%203-21%209a1303%201303%200%200048%2077%20769%20769%200%2000-16-89h-3m20%2091c-5%201-9%206-10%2010-1%203-1%203-6%203h-5v3c0%207%206%2017%2013%2020%207%204%208%204%208-2%201-5%201-5%205-5%2014-2%2018-19%207-27-3-2-8-3-12-2m18%2022c-2%204-7%208-12%208-3%201-4%201-4%203l-12%2085v3h16c18-1%2029-3%2042-9a109%20109%200%200062-81l-89-13-3%204\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6988ed2d8e5288c18ed315385e50f45e/ca0a1/NU4bfJZ.webp 223w", "/static/6988ed2d8e5288c18ed315385e50f45e/75680/NU4bfJZ.webp 445w", "/static/6988ed2d8e5288c18ed315385e50f45e/8d1ba/NU4bfJZ.webp 890w", "/static/6988ed2d8e5288c18ed315385e50f45e/dbca2/NU4bfJZ.webp 970w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6988ed2d8e5288c18ed315385e50f45e/e92b6/NU4bfJZ.png 223w", "/static/6988ed2d8e5288c18ed315385e50f45e/e66bf/NU4bfJZ.png 445w", "/static/6988ed2d8e5288c18ed315385e50f45e/4ef49/NU4bfJZ.png 890w", "/static/6988ed2d8e5288c18ed315385e50f45e/587b0/NU4bfJZ.png 970w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6988ed2d8e5288c18ed315385e50f45e/4ef49/NU4bfJZ.png",
              "alt": "NU4bfJZ",
              "title": "NU4bfJZ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/drilldown-piechart-in-react-and-d3-d62y5"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.26008968609865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'217\\'%20viewBox=\\'0%200%20400%20217\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20109v108h401V0H0v109m229%204v98h171V15H229v98m79-49l-6%203-3%202%206%207c9%2010%2010%2010%2010-3%200-11%200-11-7-9M4%20143l-1%202c0%202%201%202%2026%202h25v-5H29l-25%201m9%2034c-4%202-6%203-7%206l-2%204%209%201h8v-6c0-8%200-8-8-5\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/f773618babb3970173d4918b6f1683cb/ca0a1/techletter.app-screenshot-1561739464930.webp 223w", "/static/f773618babb3970173d4918b6f1683cb/75680/techletter.app-screenshot-1561739464930.webp 445w", "/static/f773618babb3970173d4918b6f1683cb/0ba47/techletter.app-screenshot-1561739464930.webp 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/f773618babb3970173d4918b6f1683cb/e92b6/techletter.app-screenshot-1561739464930.png 223w", "/static/f773618babb3970173d4918b6f1683cb/e66bf/techletter.app-screenshot-1561739464930.png 445w", "/static/f773618babb3970173d4918b6f1683cb/6af66/techletter.app-screenshot-1561739464930.png 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/f773618babb3970173d4918b6f1683cb/6af66/techletter.app-screenshot-1561739464930.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <h2 {...{
      "id": "heres-how-a-drilldown-piechart-works"
    }}>{`Here`}{`'`}{`s how a drilldown piechart works`}</h2>
    <p>{`You can think of a drilldown piechart as a recursive data visualization. Take a tree structure of data and you render a piechart for each level. Click on a slice, drill down into that branch.`}</p>
    <p>{`Easiest way to get started is to generate that dataset from scratch ✌️`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=function%20generateData(level%2C%20prevIndex)%20%7B%0A%20%20const%20N%20%3D%20d3.randomUniform(1%2C%2010)()%3B%0A%20%20return%20d3.range(N).map(i%20%3D%3E%20(%7B%0A%20%20%20%20value%3A%20Math.abs(d3.randomNormal()())%2C%0A%20%20%20%20id%3A%20%60%24%7Blevel%7D-%24%7Bi%7D%60%2C%0A%20%20%20%20level%3A%20level%2C%0A%20%20%20%20index%3A%20i%2C%0A%20%20%20%20prevIndex%3A%20prevIndex%2C%0A%20%20%20%20name%3A%20faker.internet.userName()%2C%0A%20%20%20%20children%3A%20level%20%3E%200%20%3F%20generateData(level%20-%201%2C%20i)%20%3A%20%5B%5D%0A%20%20%7D))%3B%0A%7D"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "558px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "69.05829596412556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'276\\'%20viewBox=\\'0%200%20400%20276\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M25%2035c-2%201-2%209-2%20104l1%20103h353V139c0-95%200-103-2-104-2-2-348-2-350%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/7aa6873cef8638997a678464bd42b358/ca0a1/techletter.app-screenshot-1561739442421.webp 223w", "/static/7aa6873cef8638997a678464bd42b358/75680/techletter.app-screenshot-1561739442421.webp 445w", "/static/7aa6873cef8638997a678464bd42b358/1c397/techletter.app-screenshot-1561739442421.webp 558w"],
                "sizes": "(max-width: 558px) 100vw, 558px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/7aa6873cef8638997a678464bd42b358/e92b6/techletter.app-screenshot-1561739442421.png 223w", "/static/7aa6873cef8638997a678464bd42b358/e66bf/techletter.app-screenshot-1561739442421.png 445w", "/static/7aa6873cef8638997a678464bd42b358/42a8d/techletter.app-screenshot-1561739442421.png 558w"],
                "sizes": "(max-width: 558px) 100vw, 558px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/7aa6873cef8638997a678464bd42b358/42a8d/techletter.app-screenshot-1561739442421.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Random number of datapoints, `}<inlineCode parentName="p">{`N`}</inlineCode>{`, create an array with `}<inlineCode parentName="p">{`d3.range`}</inlineCode>{`, loop through, generate a datapoint for each step. Random value, some identifier, save the level of recursing, the index, the parent index, a name, and then generate some children.`}</p>
    <p>{`Generating children calls this same function thus creating a recursive data structure.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "890px",
          "textAlign": "center",
          "fontStyle": "italic"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/224f2135624facefbe3990b35d719566/7321b/BI97htq.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "82.0627802690583%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'328\\'%20viewBox=\\'0%200%20400%20328\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20165v163h401V1H0v164m0%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/224f2135624facefbe3990b35d719566/ca0a1/BI97htq.webp 223w", "/static/224f2135624facefbe3990b35d719566/75680/BI97htq.webp 445w", "/static/224f2135624facefbe3990b35d719566/8d1ba/BI97htq.webp 890w", "/static/224f2135624facefbe3990b35d719566/2754d/BI97htq.webp 1184w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/224f2135624facefbe3990b35d719566/e92b6/BI97htq.png 223w", "/static/224f2135624facefbe3990b35d719566/e66bf/BI97htq.png 445w", "/static/224f2135624facefbe3990b35d719566/4ef49/BI97htq.png 890w", "/static/224f2135624facefbe3990b35d719566/7321b/BI97htq.png 1184w"],
              "sizes": "(max-width: 890px) 100vw, 890px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/224f2135624facefbe3990b35d719566/4ef49/BI97htq.png",
              "alt": "BI97htq",
              "title": "BI97htq",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We`}{`'`}{`ll use the level and index and previous index to help us pick slice colors later on.`}</p>
    <p>{`I like to keep my `}<inlineCode parentName="p">{`<App>`}</inlineCode>{` component simple so using the data and rendering the piechart looks like this:`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=%3Csvg%20width%3D%22500%22%20height%3D%22500%22%3E%0A%09%3CDrilldownPie%20data%3D%7Bdata%7D%20x%3D%7B250%7D%20y%3D%7B250%7D%20%2F%3E%0A%3C%2Fsvg%3E"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "486px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "45.73991031390135%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'184\\'%20viewBox=\\'0%200%20400%20184\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M27%2042c-2%203-1%2099%200%20102%203%203%20343%203%20346%200%201-3%202-99%200-102S29%2039%2027%2042\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8e699b41d0f6aa3ca81a71c5cbdae614/ca0a1/techletter.app-screenshot-1561739426193.webp 223w", "/static/8e699b41d0f6aa3ca81a71c5cbdae614/75680/techletter.app-screenshot-1561739426193.webp 445w", "/static/8e699b41d0f6aa3ca81a71c5cbdae614/aba12/techletter.app-screenshot-1561739426193.webp 486w"],
                "sizes": "(max-width: 486px) 100vw, 486px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8e699b41d0f6aa3ca81a71c5cbdae614/e92b6/techletter.app-screenshot-1561739426193.png 223w", "/static/8e699b41d0f6aa3ca81a71c5cbdae614/e66bf/techletter.app-screenshot-1561739426193.png 445w", "/static/8e699b41d0f6aa3ca81a71c5cbdae614/4ee7f/techletter.app-screenshot-1561739426193.png 486w"],
                "sizes": "(max-width: 486px) 100vw, 486px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/8e699b41d0f6aa3ca81a71c5cbdae614/4ee7f/techletter.app-screenshot-1561739426193.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`An svg element with a `}<inlineCode parentName="p">{`<DrilldownPie>`}</inlineCode>{` component.`}</p>
    <h2 {...{
      "id": "drilldownpie-component"
    }}>{`DrilldownPie component`}</h2>
    <p><img parentName="p" {...{
        "src": "/0e5b94e918385db6a12637b96a1ef052/O9xL7km.gif",
        "alt": null
      }}></img></p>
    <p>{`Ok the colors aren`}{`'`}{`t perfect, the drilling down is confusing without an animation, and you can`}{`'`}{`t drill up just yet. But it`}{`'`}{`s a great start!`}</p>
    <p>{`Three components play together:`}</p>
    <ol>
      <li parentName="ol">{`A styled `}<inlineCode parentName="li">{`<Path>`}</inlineCode>{` component handles colors`}</li>
      <li parentName="ol">{`An `}<inlineCode parentName="li">{`<Arc>`}</inlineCode>{` component renders arcs, deals with mouseovers, and registers clicks`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`<DrilldownPie>`}</inlineCode>{` component holds it all together and deals with drilling`}</li>
    </ol>
    <h3 {...{
      "id": "path"
    }}>{`Path`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Path>`}</inlineCode>{` component is a styled SVG path that uses props to decide the color of each slice.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=const%20Path%20%3D%20styled.path%60%0A%20%20fill%3A%20%24%7Bprops%20%3D%3E%0A%20%20%20%20props.level%20%3D%3D%3D%204%0A%20%20%20%20%20%20%3F%20d3.schemePaired%5Bprops.index%5D%0A%20%20%20%20%20%20%3A%20d3.color(d3.schemePaired%5Bprops.prevIndex%5D).brighter(props.index%20%2F%202)%7D%3B%0A%20%20cursor%3A%20pointer%3B%0A%20%20stroke%3A%20black%3B%0A%60%3B"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "726px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "43.04932735426009%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'172\\'%20viewBox=\\'0%200%20400%20172\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M18%2028v118h364V28c-1-3-363-3-364%200\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/eae4dd846adf6b81fe47b4b219747657/ca0a1/techletter.app-screenshot-1561739426130.webp 223w", "/static/eae4dd846adf6b81fe47b4b219747657/75680/techletter.app-screenshot-1561739426130.webp 445w", "/static/eae4dd846adf6b81fe47b4b219747657/06b0e/techletter.app-screenshot-1561739426130.webp 726w"],
                "sizes": "(max-width: 726px) 100vw, 726px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/eae4dd846adf6b81fe47b4b219747657/e92b6/techletter.app-screenshot-1561739426130.png 223w", "/static/eae4dd846adf6b81fe47b4b219747657/e66bf/techletter.app-screenshot-1561739426130.png 445w", "/static/eae4dd846adf6b81fe47b4b219747657/f8067/techletter.app-screenshot-1561739426130.png 726w"],
                "sizes": "(max-width: 726px) 100vw, 726px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/eae4dd846adf6b81fe47b4b219747657/f8067/techletter.app-screenshot-1561739426130.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Level 4 is the top level 👉 use a color scheme from D3 and the slice index to pick a color.`}</p>
    <p>{`With other levels the idea was to take the base color and make it progressively brighter based on the index. Works okay for the first level down, didn`}{`'`}{`t quite work later on.`}</p>
    <p>{`Might have to change how we approach this. Maybe colors as part of the dataset itself?`}</p>
    <p><img parentName="p" {...{
        "src": "/3e45a447846c71418e60682a1d331448/media-6utVzLiyU9OuHbd70D-giphy-downsized-medium.gif",
        "alt": "thinking_hard giphy"
      }}></img></p>
    <h3 {...{
      "id": "arc"
    }}>{`Arc`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<Arc>`}</inlineCode>{` component renders a single slice of the piechart and handles mouseover events using hooks to change size. Messing with the radius creates the effect of individual slices popping out.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=const%20Arc%20%3D%20(%7B%20arcData%2C%20onClick%20%7D)%20%3D%3E%20%7B%0A%20%20const%20%5BradiusAdd%2C%20setRadiusAdd%5D%20%3D%20useState(0)%3B%0A%0A%20%20const%20arc%20%3D%20d3%0A%20%20%20%20.arc()%0A%20%20%20%20.innerRadius(15%20%2B%20radiusAdd%20%2F%202)%0A%20%20%20%20.outerRadius(105%20%2B%20radiusAdd)%3B%0A%0A%20%20function%20mouseOver()%20%7B%0A%20%20%20%20setRadiusAdd(20)%3B%0A%20%20%7D%0A%0A%20%20function%20mouseOut()%20%7B%0A%20%20%20%20setRadiusAdd(0)%3B%0A%20%20%7D%0A%0A%20%20return%20(%0A%20%20%20%20%3CPath%0A%20%20%20%20%20%20d%3D%7Barc(arcData)%7D%0A%20%20%20%20%20%20index%3D%7BarcData.data.index%7D%0A%20%20%20%20%20%20level%3D%7BarcData.data.level%7D%0A%20%20%20%20%20%20prevIndex%3D%7BarcData.data.prevIndex%7D%0A%20%20%20%20%20%20onMouseOver%3D%7BmouseOver%7D%0A%20%20%20%20%20%20onMouseOut%3D%7BmouseOut%7D%0A%20%20%20%20%20%20onClick%3D%7BonClick%7D%0A%20%20%20%20%2F%3E%0A%20%20)%3B%0A%7D%3B"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "486px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "138.56502242152467%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'554\\'%20viewBox=\\'0%200%20400%20554\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M27%2042c-2%203-1%20469%200%20472%203%203%20343%203%20346%200%201-3%202-469%200-472S29%2039%2027%2042\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9f18384ff9b9d9bbd93c95d2a917b514/ca0a1/techletter.app-screenshot-1561739426232.webp 223w", "/static/9f18384ff9b9d9bbd93c95d2a917b514/75680/techletter.app-screenshot-1561739426232.webp 445w", "/static/9f18384ff9b9d9bbd93c95d2a917b514/aba12/techletter.app-screenshot-1561739426232.webp 486w"],
                "sizes": "(max-width: 486px) 100vw, 486px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/9f18384ff9b9d9bbd93c95d2a917b514/e92b6/techletter.app-screenshot-1561739426232.png 223w", "/static/9f18384ff9b9d9bbd93c95d2a917b514/e66bf/techletter.app-screenshot-1561739426232.png 445w", "/static/9f18384ff9b9d9bbd93c95d2a917b514/4ee7f/techletter.app-screenshot-1561739426232.png 486w"],
                "sizes": "(max-width: 486px) 100vw, 486px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/9f18384ff9b9d9bbd93c95d2a917b514/4ee7f/techletter.app-screenshot-1561739426232.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`That`}{`'`}{`s the whole component. Let`}{`'`}{`s break it down :)`}</p>
    <p><strong parentName="p">{`First`}</strong>{` we`}{`'`}{`ve got the state management: A single `}<inlineCode parentName="p">{`radiusAdd`}</inlineCode>{` variable that tells us how much to add or take away from the default radius of our arc.`}</p>
    <p><strong parentName="p">{`Second`}</strong>{` we`}{`'`}{`ve got the `}<inlineCode parentName="p">{`d3.arc`}</inlineCode>{` generator. Takes some configuration and produces path definitions using SVG`}{`'`}{`s crazy path language.`}</p>
    <p><strong parentName="p">{`Third`}</strong>{` we`}{`'`}{`ve got 2 functions calling the `}<inlineCode parentName="p">{`setRadiusAdd`}</inlineCode>{` setter to change the radius. That triggers a re-render, the arc generator sees the new value, creates a different path definition 👌`}</p>
    <p><strong parentName="p">{`Fourth`}</strong>{` we render our styled `}<inlineCode parentName="p">{`<Path>`}</inlineCode>{` component making sure to pass in all the right props. The path definition, the level and index for color, and all the event listeners.`}</p>
    <h3 {...{
      "id": "drilldownpie"
    }}>{`DrilldownPie`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<DrilldownPie>`}</inlineCode>{` component ties this all together.`}</p>
    <p><a parentName="p" {...{
        "href": "https://carbon.now.sh/?bg=rgba(255,255,255,1)&t=seti&l=javascript&ds=true&wc=true&wa=true&pv=48px&ph=32px&ln=false&code=const%20DrilldownPie%20%3D%20(%7B%20data%2C%20x%2C%20y%20%7D)%20%3D%3E%20%7B%0A%20%20const%20%5BrenderData%2C%20setRenderData%5D%20%3D%20useState(data)%3B%0A%0A%20%20const%20pie%20%3D%20d3.pie().value(d%20%3D%3E%20d.value)%3B%0A%0A%20%20function%20drilldown(index)%20%7B%0A%20%20%20%20setRenderData(renderData%5Bindex%5D.children)%3B%0A%20%20%7D%0A%0A%20%20return%20(%0A%20%20%20%20%3Cg%20transform%3D%7B%60translate(%24%7Bx%7D%2C%20%24%7By%7D)%60%7D%3E%0A%20%20%20%20%20%20%7Bpie(renderData).map(d%20%3D%3E%20(%0A%20%20%20%20%20%20%20%20%3CArc%20arcData%3D%7Bd%7D%20key%3D%7Bd.id%7D%20onClick%3D%7B()%20%3D%3E%20drilldown(d.index)%7D%20%2F%3E%0A%20%20%20%20%20%20))%7D%0A%20%20%20%20%3C%2Fg%3E%0A%20%20)%3B%0A%7D%3B"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "686px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "69.05829596412556%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'277\\'%20viewBox=\\'0%200%20400%20277\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M20%2029a4632%204632%200%20001%20220h359l1-111V29l-181-1-180%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4b2b7d2b6aef46a78a26edd5cb3709ff/ca0a1/techletter.app-screenshot-1561739426244.webp 223w", "/static/4b2b7d2b6aef46a78a26edd5cb3709ff/75680/techletter.app-screenshot-1561739426244.webp 445w", "/static/4b2b7d2b6aef46a78a26edd5cb3709ff/7dbce/techletter.app-screenshot-1561739426244.webp 686w"],
                "sizes": "(max-width: 686px) 100vw, 686px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4b2b7d2b6aef46a78a26edd5cb3709ff/e92b6/techletter.app-screenshot-1561739426244.png 223w", "/static/4b2b7d2b6aef46a78a26edd5cb3709ff/e66bf/techletter.app-screenshot-1561739426244.png 445w", "/static/4b2b7d2b6aef46a78a26edd5cb3709ff/f6386/techletter.app-screenshot-1561739426244.png 686w"],
                "sizes": "(max-width: 686px) 100vw, 686px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4b2b7d2b6aef46a78a26edd5cb3709ff/f6386/techletter.app-screenshot-1561739426244.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <p>{`Less code even than the Arc component god I love hooks 😱`}</p>
    <p><strong parentName="p">{`First`}</strong>{` we`}{`'`}{`ve got state. We copy our data into it. That`}{`'`}{`s so we can do the drilling down. Haven`}{`'`}{`t tried how this plays with data changing from the outside ... trigger a re-render I assume 🤔`}</p>
    <p><strong parentName="p">{`Second`}</strong>{` we`}{`'`}{`ve got a `}<inlineCode parentName="p">{`d3.pie`}</inlineCode>{` generator. Takes our data and creates an array of objects perfectly suited for the `}<inlineCode parentName="p">{`d3.arc`}</inlineCode>{` generator. Angles and values and all.`}</p>
    <p><strong parentName="p">{`Third`}</strong>{` the `}<inlineCode parentName="p">{`drilldown`}</inlineCode>{` function uses our state setter to move data one level down. Take the children, set them as current data, trigger re-render, magic. 🧙‍♂️`}</p>
    <p><strong parentName="p">{`Fourth`}</strong>{` render it all in a loop.`}</p>
    <p>{`And that`}{`'`}{`s how you build a drilldown piechart with React and D3 and hooks.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/drilldown-piechart-in-react-and-d3-d62y5"
      }}><figure parentName="a" {...{
          "className": "gatsby-resp-image-figure",
          "style": {
            "textAlign": "center",
            "fontStyle": "italic"
          }
        }}>{`
    `}<span parentName="figure" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "640px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54.26008968609865%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'217\\'%20viewBox=\\'0%200%20400%20217\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%20109v108h401V0H0v109m229%204v98h171V15H229v98M3%20145l1%203h50v-6H3v3m5%2039c-6%204-6%205-2%206h2l-3%203-2%202h20v-11l-5-1c-6-2-6-2-10%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4978ecb9ecb43e00f20927466af54ef1/ca0a1/techletter.app-screenshot-1561739491897.webp 223w", "/static/4978ecb9ecb43e00f20927466af54ef1/75680/techletter.app-screenshot-1561739491897.webp 445w", "/static/4978ecb9ecb43e00f20927466af54ef1/0ba47/techletter.app-screenshot-1561739491897.webp 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/4978ecb9ecb43e00f20927466af54ef1/e92b6/techletter.app-screenshot-1561739491897.png 223w", "/static/4978ecb9ecb43e00f20927466af54ef1/e66bf/techletter.app-screenshot-1561739491897.png 445w", "/static/4978ecb9ecb43e00f20927466af54ef1/6af66/techletter.app-screenshot-1561739491897.png 640w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/4978ecb9ecb43e00f20927466af54ef1/6af66/techletter.app-screenshot-1561739491897.png",
                "alt": "Click through for source",
                "title": "Click through for source",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
            "className": "gatsby-resp-image-figcaption"
          }}>{`Click through for source`}</figcaption>{`
  `}</figure></a></p>
    <h2 {...{
      "id": "ps"
    }}>{`PS`}</h2>
    <p>{`I`}{`'`}{`m going to continue this challenge either Friday night (today) or Sunday afternoon. Want to figure out how to make the drilldown transition animated and get drilling up to work.`}</p>
    <p>{`Drilling up is easy in principle, but gets tricky if you want to support arbitrary levels. That`}{`'`}{`s because we aren`}{`'`}{`t using real recursion to drill down 😅`}</p>
    <p>{`See you at the stream maybe?`}</p>
    <p>{`Cheers, ~Swizec`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      